import { render, staticRenderFns } from "./LegendModal.vue?vue&type=template&id=23249935&scoped=true&"
import script from "./LegendModal.vue?vue&type=script&lang=js&"
export * from "./LegendModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23249935",
  null
  
)

export default component.exports