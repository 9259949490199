<template>
  <t-modal v-model="show" header="Legend">
    <ul class="my-5 mx-3">
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_future_availability") }}</span>
        <div class="w-32 h-8" style="background-color: #10b981"></div>
      </li>
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_timeout_and_no_availability") }}</span>
        <div class="w-32 h-8" style="background-color: #8b5cf6"></div>
      </li>
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_not_available") }}</span>
        <div class="w-32 h-8" style="background-color: #ff4500"></div>
      </li>
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_free_day") }}</span>
        <div class="w-32 h-8" style="background-color: #d89a28"></div>
      </li>
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_canceled") }}</span>
        <div class="w-32 h-8" style="background-color: #bbbbbb"></div>
      </li>
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_holiday") }}</span>
        <div
          class="w-32 h-8 flex justify-center items-center"
          style="background-color: #bbbbbb"
        >
          <i class="fas fa-info-circle" style="color: white"></i>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <div class="flex justify-end">
        <t-button type="button" class="text-sm" @click="show = false">{{ $t("close") }}</t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
export default {
  name: "LegendModal",
  props: ["value"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
