<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div v-if="!hideView" class="px-2">
        <div class="flex gap-4 mt-4 items-center flex-wrap lg:flex-nowrap">
          <div class="flex items-center justify-between w-60">
            <label class="w-16 font-semibold">{{ $t("week") }}</label>
            <t-select
              class="w-44"
              v-model="filterWeek"
              :options="weeks"
            ></t-select>
          </div>
          <div class="flex items-center justify-between w-60">
            <label class="w-16 font-semibold">{{ $t("job_type") }}</label>
            <t-select
              class="w-44"
              v-model="filterJobType"
              :options="jobFilters"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
          <div class="ml-auto mt-4 flex flex-wrap md:mt-0 md:block">
            <button
              class="text-sm text-blue-500 py-1 w-1/2 md:w-auto md:mx-4"
              @click="publish"
            >
              <i class="fas fa-cloud-upload-alt"></i>
              Publiceren
            </button>
            <button
              class="text-sm text-blue-500 py-1 w-1/2 md:w-auto md:mx-4"
              @click="showCreateScheduleModal"
            >
              <i class="fas fa-plus"></i>
              Toevegen
            </button>
            <button
              class="text-sm text-blue-500 py-1 w-1/2 md:w-auto md:mx-4"
              @click="showHolidayModal"
            >
              <i class="fas fa-plane"></i>
              Time outs
            </button>
            <button
              class="text-sm text-blue-500 w-1/2 md:w-auto md:mx-4"
              @click="showInputNoteModal"
            >
              <i class="fas fa-exclamation-circle"></i>
              Notities
            </button>
            <button
              class="text-sm text-blue-500 w-1/2 mx-auto md:w-auto md:mx-4"
              @click="toggleLegendModal = true"
            >
              <i class="fas fa-question-circle"></i>
              Help
            </button>
          </div>
        </div>
      </div>
      <t-alert variant="danger" class="my-2" :show="showErrorAlert">
        {{ $t("not_all_employees_available") }}
      </t-alert>

      <!-- Add Schedule Modal -->
      <t-modal v-model="toggleCreateScheduleModal">
        <form class="flex flex-col px-2 pt-4" @submit="onCreateSchedule">
          <label class="font-semibold mb-1">{{ $t("date") }}</label>
          <t-select
            class="mb-2"
            v-model="form.date"
            :options="daysOfWeek"
          ></t-select>
          <label class="font-semibold mb-1">{{ $t("edit_job") }}</label>
          <t-select
            class="mb-2"
            v-model="form.jobId"
            :options="jobs"
            value-attribute="id"
            text-attribute="name"
            required
          ></t-select>
          <label class="font-semibold mb-1">{{ $t("start") }}</label>
          <t-select
            class="mb-2"
            v-model="form.from"
            :options="timeslots.start"
            required
          ></t-select>
          <label class="font-semibold mb-1">{{ $t("ending") }}</label>
          <t-select
            class="mb-2"
            v-model="form.until"
            :options="timeslots.end"
            required
          ></t-select>

          <div class="flex justify-end mt-2">
            <t-button
              type="button"
              class="text-sm"
              @click="toggleCreateScheduleModal = false"
              :disabled="loading"
              >{{ $t("close") }}</t-button
            >
            <t-button
              type="submit"
              class="text-sm ml-2"
              variant="primary"
              :disabled="loading"
              >{{ $t("add") }}</t-button
            >
          </div>
        </form>
      </t-modal>
      <!-- ./Add Schedule Modal -->

      <!-- Holiday Modal -->
      <t-modal v-model="toggleHolidayModal" :header="$t('time_outs')">
        <ul
          class="list-disc mx-8"
          v-for="holiday in approvedHolidays"
          :key="holiday.id"
        >
          <li class="text-sm">
            {{ holiday.user.name }} ({{ holiday.from }} - {{ holiday.until }})
          </li>
        </ul>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button class="text-sm" @click="toggleHolidayModal = false">
              {{ $t("close") }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <!-- ./Holiday Modal -->

      <!-- Notes Modal -->
      <t-modal v-model="toggleInputNoteModal" :header="$t('notes')">
        <div class="w-96"></div>
        <p class="text-sm mx-2" v-html="formattedInputNoteText"></p>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              class="text-sm mr-3"
              @click="toggleInputNoteModal = false"
              >{{ $t("close") }}</t-button
            >
            <t-button
              class="text-sm"
              variant="primary"
              @click="$router.push('/note')"
              >{{ $t("update") }}</t-button
            >
          </div>
        </template>
      </t-modal>
      <!-- ./Notes Modal -->

      <legend-modal v-model="toggleLegendModal" />

      <router-view
        v-if="!hideView"
        ref="newRooster"
        @setLoading="loading = $event"
      />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import LegendModal from "@/components/LegendModal.vue";
import { actions, getters } from "@/constants/state";
import { checkIfPublished } from "@/services/published-schedule-service";
import { isAllUserAvailable } from "@/services/scheduledt-service";
import { getByDateRange } from "@/services/template-schedule-service";
import {
  getDaysOfWeek,
  getEndTimeDay,
  getEndWeekDuration,
  getTimeSlotOptions,
  getWeekOptions,
} from "@/utils/misc";
import { first, get, isEmpty } from "lodash";
import moment from "@/utils/moment-utc";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout, LegendModal },
  data: () => ({
    loading: false,
    hideView: false,
    showErrorAlert: false,
    toggleCreateScheduleModal: false,
    toggleHolidayModal: false,
    toggleInputNoteModal: false,
    toggleLegendModal: false,
    form: {
      date: null,
      jobId: null,
      from: null,
      until: null,
    },
  }),
  async mounted() {
    this.loading = true;
    this.hideView = true;

    try {
      let jobFilterId = this.mainFilter.jobFilterId;

      if (!this.mainFilter.jobFilterId) {
        jobFilterId = get(first(this.jobFilters), "id");
      }

      const { data } = await isAllUserAvailable(
        this.mainFilter.week,
        getEndWeekDuration(this.mainFilter.week),
        jobFilterId
      );
      this.showErrorAlert = !data;
    } catch (error) {
      console.error("DEBUG: isAllUserAvailable", error);
    }

    this.loading = false;
    this.hideView = false;
  },
  methods: {
    ...mapActions({
      getApprovedHolidays: actions.HOLIDAYS_FETCH_APPROVED_ACTION,
      setMainFilterAction: actions.SESSION_SET_MAIN_FILTER_ACTION,
      getSchedulesDt: actions.SCHEDULEDT_FETCH_ACTION,
      publishScheduleDt: actions.SCHEDULEDT_PUBLISH_ACTION,
      createSchedule: actions.SCHEDULEDT_CREATE_ACTION,
      getJobs: actions.JOBS_FETCH_ACTION,
      getInputNote: actions.INPUTNOTE_FETCH_ACTION,
    }),
    getApprovedHolidaysByDate() {
      const now = moment();
      const from = now.isAfter(this.mainFilter.week)
        ? now.format("YYYY-MM-DD")
        : moment(this.mainFilter.week).format("YYYY-MM-DD");

      return this.getApprovedHolidays({
        from,
        until: getEndWeekDuration(from),
      });
    },
    async showInputNoteModal() {
      this.loading = true;

      try {
        await this.getInputNote();
      } catch (error) {
        console.error("DEBUG: showInputNoteModal", error);
      }

      this.toggleInputNoteModal = true;
      this.loading = false;
    },
    async showCreateScheduleModal() {
      this.loading = true;

      try {
        await this.getJobs();
        this.form = {
          from: "07:00",
          until: "02:00",
          date: this.mainFilter.week,
          jobId: get(first(this.jobs), "id"),
        };
      } catch (error) {
        console.error("DEBUG: showCreateScheduleModal", error);
      }

      this.toggleCreateScheduleModal = true;
      this.loading = false;
    },
    async showHolidayModal() {
      this.loading = true;

      try {
        await this.getApprovedHolidaysByDate();
      } catch (error) {
        console.error("DEBUG: showHolidayModal", error);
      }

      this.toggleHolidayModal = true;
      this.loading = false;
    },
    async onCreateSchedule(e) {
      e.preventDefault();
      this.loading = true;

      try {
        await this.createSchedule({
          from: `${this.form.date} ${this.form.from}:00`,
          until: getEndTimeDay(this.form.date, this.form.from, this.form.until),
          jobId: this.form.jobId,
        });

        this.toggleCreateScheduleModal = false;

        if (this.$route.path !== "/new-rooster") {
          this.$router.push("/new-rooster");
        } else {
          await this.getSchedulesDt({
            from: this.mainFilter.week,
            until: getEndWeekDuration(this.mainFilter.week),
            jobFilterId: this.mainFilter.jobFilterId,
          });
        }
      } catch (error) {
        console.error("DEBUG: onCreateSchedule", error);
      }

      this.loading = false;
    },
    async publish() {
      const { isCancel } = await this.$dialog.confirm(
        this.$i18n.t("are_you_sure")
      );

      if (isCancel) {
        return;
      }

      this.loading = true;

      try {
        await this.publishScheduleDt({
          from: this.mainFilter.week,
          until: getEndWeekDuration(this.mainFilter.week),
          jobFilterId: this.mainFilter.jobFilterId,
        });
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: publish", error);
      }

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      jobFilters: getters.JOBS_JOB_FILTERS_GETTER,
      schedulesDt: getters.SCHEDULEDT_GETTER,
      mainFilter: getters.SESSION_MAIN_FILTER_GETTER,
      jobs: getters.JOBS_GETTER,
      approvedHolidays: getters.HOLIDAYS_APPROVED_GETTER,
      inputNote: getters.INPUTNOTE_GETTER,
    }),
    formattedInputNoteText() {
      if (!get(this.inputNote, "text")) {
        return "";
      }

      return this.inputNote.text.replace(/(\r\n|\n|\r)/gm, "<br>");
    },
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
    daysOfWeek() {
      return getDaysOfWeek(this.mainFilter.week);
    },
    timeslots() {
      return getTimeSlotOptions();
    },
    filterJobType: {
      get() {
        return +this.mainFilter.jobFilterId;
      },
      set(jobFilterId) {
        this.setMainFilterAction({
          ...this.mainFilter,
          jobFilterId,
        });
      },
    },
    filterWeek: {
      get() {
        return this.mainFilter.week;
      },
      set(week) {
        this.setMainFilterAction({
          ...this.mainFilter,
          week,
        });
      },
    },
    newRoosterFilters() {
      return this.filterWeek && this.filterJobType;
    },
  },
  watch: {
    newRoosterFilters: {
      deep: true,
      handler: async function () {
        this.loading = true;

        try {
          await this.getSchedulesDt({
            from: this.mainFilter.week,
            until: getEndWeekDuration(this.mainFilter.week),
            jobFilterId: this.mainFilter.jobFilterId,
          });

          const { data } = await getByDateRange({
            from: this.mainFilter.week,
            until: getEndWeekDuration(this.mainFilter.week),
            jobFilterId: this.mainFilter.jobFilterId,
          });

          const isPublished = await checkIfPublished({
            start: this.mainFilter.week,
            jobFilterId: this.mainFilter.jobFilterId,
          });

          if (this.$route.name !== "Select Template") {
            this.$refs.newRooster.loadData();
          }

          if (
            this.$route.name === "New Rooster List" &&
            isEmpty(data) &&
            !get(isPublished, "data")
          ) {
            return this.$router.push({
              name: "Select Template",
              params: { avoidRefresh: true },
            });
          }

          if (this.$route.name !== "New Rooster List") {
            if (
              (!isEmpty(data) && !get(isPublished, "data")) ||
              get(isPublished, "data")
            ) {
              return this.$router.push({
                name: "New Rooster List",
                params: { avoidRefresh: true },
              });
            }
          }

          await this.getApprovedHolidaysByDate();
          this.loading = false;
        } catch (error) {
          console.error("DEBUG: filterWeek watch", error);
        }

        this.loading = false;
      },
    },
  },
};
</script>
